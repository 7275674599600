<template>
    <div>
        <b-form ref="form" @submit.prevent="submit">
            <!-- id, active -->
            <b-form-group
                    class="mb-0"
                    label-cols="6"
                    :label="record.id === 0 ? '' : $t('form.id_value', {value: record.id})"
                    label-size=""
                    label-class="pt-0"
            >
                <b-form-checkbox
                        id="active"
                        class="float-right"
                        switch
                        v-model="record.active"
                        name="active"
                        value="true"
                        unchecked-value="false"
                >
                    {{activeText}}
                </b-form-checkbox>
            </b-form-group>
            <!-- meta -->
            <b-form-group
                    :label="$tc('form.meta.data', 0)"
                    class="mb-0 group-title p-0"
                    label-class="font-weight-bold"
                    label-align-sm="left"
                    label-cols-sm="12"
                    label-size=""
            >
                <b-form-row>
                    <!-- name -->
                    <b-form-group
                            id="group-meta.name"
                            class="col-sm-12 col-md-6"
                            label-class="mb-0"
                            :label="$t('form.name')"
                            label-for="meta.name"
                            description=""
                            :state="valid_metaName"
                    >
                        <b-form-input
                                id="meta.name"
                                v-model="record.meta.name"
                                :state="valid_metaName"
                                required
                                :placeholder="$t('form.required_field')"
                        ></b-form-input>
                    </b-form-group>
                </b-form-row>
            </b-form-group>
            <!-- content -->
            <b-form-group
                    :label="$t('form.content')"
                    class="mb-0 group-title p-0"
                    label-class="font-weight-bold"
                    label-align-sm="left"
                    label-cols-sm="12"
                    label-size=""
            >
                <b-form-row>
                    <!-- order -->
                    <b-form-group
                            id="group-order"
                            class="col-sm-6 col-md-3"
                            label-class="mb-0"
                            :label="$t('form.order')"
                            label-for="order"
                            description=""
                    >
                        <b-form-input
                                id="order"
                                type="number"
                                v-model="record.order"
                        ></b-form-input>
                    </b-form-group>
                </b-form-row>
            </b-form-group>
            <!-- locale -->
            <b-form-group
                    :label="$t('form.locale.locale')"
                    class="mb-0 group-title p-0"
                    label-class="font-weight-bold"
                    label-align-sm="left"
                    label-cols-sm="12"
                    label-size=""
            >
                <b-card no-body>
                    <b-tabs pills card>
                        <b-tab :title="locale.locale"
                               :active="locale.locale === $i18n.locale"
                               v-for="locale in data.locale" style="background: white">
                            <FormProjectLocale
                                    :locale="locale.locale"
                                    :record.sync="setRecordLocale[locale.locale]"
                            ></FormProjectLocale>
                        </b-tab>
                    </b-tabs>
                </b-card>
            </b-form-group>
            <!--  -->
        </b-form>
    </div>
</template>

<script>
    import FormProjectLocale from "./FormProjectLocale";

    export default {
        name: "FormProject",
        components: {FormProjectLocale},
        props: {
            form: {
                valid: false,
                record: {},
            },
            data: {},
            modalBus: Object,
        },
        data() {
            return {
                valid: null,
                record: {
                    id: 0,
                    active: false,
                    order: 0,
                    meta: {
                        name: '',
                    },
                    locale: {},
                },
            }
        },
        mounted() {
            this.modalBus.$on('submit', this.submit);

            if (this.form.record != null) {
                this.$set(this, 'record', this.form.record);
            }
        },
        methods: {
            // <-- form -->
            submit() {
                if (this.$refs.form === undefined) {
                    return;
                }
                this.valid = this.$refs.form.checkValidity();

                this.$set(this.form, 'record', this.record);
                this.$set(this.form, 'valid', this.valid);
            }
        },
        computed: {
            activeText: {
                get() {
                    return !this.record.active || this.record.active === 'false' || this.record.active === undefined
                        ? this.$t('form.inactive')
                        : this.$t('form.active');
                }
            },
            setRecordLocale() {
                this.data.locale.forEach((value, index) => {
                    // <-- add missing locale record -->
                    if (Object.keys(this.record.locale).indexOf(value.locale) === -1) {
                        this.$set(this.record.locale, value.locale, {
                            name: '',
                            notes: ''
                        });
                    }
                });
                return this.record.locale;
            },
            // <-- meta -->
            valid_metaName() {
                if (this.valid === null) {
                    return null;
                }
                return this.record.meta.name.length > 0 ? null : false;
            },
        },
        filters: {},
        watch: {}
    }
</script>

<style scoped>
    .group-title {
        /*color: gray;*/
    }
</style>
